import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {Icon, Heading, Paragraph, Stack, Surface} from '@halp/ui';
import {
	FUNNEL_STAGE_ORDER,
	METRIC_COLORS,
	METRIC_TITLES,
} from 'utils/constants';
import {useQuery} from '@halp/api/graphql';
import style from './PartnerMetrics.module.css';
import {PartnerDashboardDocument} from './PartnerDashboard.query';

interface Metrics {
	signup: number;
	verification: number;
	getStarted: number;
	discover: number;
	prep: number;
	submit: number;
	accepted: number;
	visa: number;
	setUp: number;
	placed: number;
}

export function PartnerMetrics() {
	const {data} = useQuery(PartnerDashboardDocument);

	const metrics = data?.session?.partnerProfile?.studentsByFunnelStage;

	if (!metrics) {
		return null;
	}

	const orderedMetrics = Object.keys(metrics)
		.sort(
			(a, b) => FUNNEL_STAGE_ORDER.indexOf(a) - FUNNEL_STAGE_ORDER.indexOf(b),
		)
		.reduce((obj: {[key: string]: number}, key) => {
			obj[key] = metrics[key as keyof Metrics];
			return obj;
		}, {});

	return (
		<Stack
			direction="column"
			alignItems="stretch"
			justifyContent="flex-start"
			spacing="md"
		>
			<Heading type="h3">Total Converted Numbers</Heading>
			<Stack
				direction="row"
				alignItems="stretch"
				justifyContent="flex-start"
				wrap="wrap"
				spacing="md"
			>
				{metrics
					? Object.keys(orderedMetrics).map((funnelStage, index) => {
							return (
								<Stack
									direction="row"
									justifyContent="flex-start"
									spacing="xs"
									key={funnelStage}
								>
									<Surface
										color={METRIC_COLORS[funnelStage]}
										className={style.MetricCard}
									>
										<Stack
											direction="column"
											alignItems="flex-start"
											justifyContent="flex-start"
											spacing="xs"
										>
											<Heading type="h3" color={METRIC_COLORS[funnelStage]}>
												{index + 1}
											</Heading>
											<Paragraph color="grey">
												{METRIC_TITLES[funnelStage]}
											</Paragraph>
											<Heading type="h4">
												{metrics[funnelStage as keyof Metrics]}
											</Heading>
										</Stack>
									</Surface>
									{index !== 6 ? (
										<Icon icon={faAngleRight} color="grey" />
									) : null}
								</Stack>
							);
						})
					: null}
			</Stack>
		</Stack>
	);
}
