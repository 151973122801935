import {faEnvelope, faUser} from '@fortawesome/free-solid-svg-icons';
import {Avatar, Heading, Paragraph, Stack, Icon} from '@halp/ui';

interface Props {
	businessName: string;
	name?: string | null;
	email?: string | null;
}

export function PartnerInfo({businessName, name, email}: Props) {
	return (
		<Stack direction="row" spacing="md" justifyContent="flex-start">
			<Avatar text={businessName} color="secondary" />
			<Stack
				direction="column"
				justifyContent="space-between"
				alignItems="baseline"
				spacing="none"
			>
				<Heading type="h4" spacing="none">
					{businessName}
				</Heading>
				<Stack justifyContent="flex-start" spacing="xs">
					<Icon icon={faUser} />
					<Paragraph>{name}</Paragraph>
				</Stack>
				<Stack justifyContent="flex-start" spacing="xs">
					<Icon icon={faEnvelope} />
					<Paragraph>{email}</Paragraph>
				</Stack>
			</Stack>
		</Stack>
	);
}
